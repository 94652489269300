<template>
  <div class="adminLayout">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar,
  },
};
</script>

<style scoped>
/* .adminLayout {
  height: 100% !important;
} */
</style>
