export default (roles, allrequired = false) => {
    let canEnter = false;
    const userRole = window.localStorage.getItem('user_role');
    const roleUsers = JSON.parse(userRole);
    // const roleUsers = userRole;
    if (!allrequired) {
        canEnter = roles.filter(roleName => {
            if (roleName == roleUsers) {
                return true;
            }
            return false;
        }).length > 0;
    }
    return canEnter;
}


// export default (roles, allrequired = false) => {
//     let canEnter = false;
//
//     const user_role = window.localStorage.getItem('user_role');
//     const userPermission = window.localStorage.getItem("userPermission");
//     const getRoles = JSON.parse(user_role);
//     const getPermission = userPermission;
//     let roleUsers = getRoles.concat(getPermission);
//     console.log(roleUsers, 55)
//     roleUsers = [...new Set([...getRoles, ...getPermission])];
//
//     if (!roleUsers || !roles) {
//         return canEnter;
//     }
//
//     // ກວດສອບຖ້າຫາກ Permission ທີ່ສົ່ງມາບໍ່ເເມ່ນ ລະບົບຈັ່ງເຮັດວຽກ Function ນີ້
//     if (!Array.isArray(roles)) {
//         canEnter = roleUsers.includes(roles);
//     }
//     else {
//         // Permission ຕ້ອງຖືກຕ້ອງທັງໝົດຈັ່ງເຮັດວຽກ Function ນີ້
//         if (allrequired) {
//             canEnter = roles.every(role => roleUsers.includes(role));
//         }
//         // Permission ຖືກຕ້ອງອັນໃດອັນໜ່ິງກະເຮັດວຽກ Function ນີ້
//         else {
//             canEnter = roleUsers.filter(role => {
//                 return roles.includes(role);
//             }).length > 0;
//         }
//     }
//
//     // ກໍລະນີ Permission ບໍ່ຖືກຕ້ອງອັນໃດເລີຍລະບົບຈະ (Return True) ອອກໄປ
//     return canEnter;
//
//
// }
