<template>
  <div class="privacy">
    <div class="card-privacy">
      <div data-custom-class="body">
        <div><strong><span style="font-size: 26px;">PRIVACY NOTICE</span></strong></div>
        <div>&nbsp;</div>
        <div><span style="color: #7f7f7f;"><strong><span
            style="font-size: 15px;">Last updated February 28, 2022</span></strong></span></div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;">This privacy notice for www.synergylao.com ("<strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:</span></span>
          <div>
            <div style="line-height: 1.5;">
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at www.synergylao.com.</span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><strong><span
                  style="font-size: 15px;">SUMMARY OF KEY POINTS</span></strong>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click&nbsp;</strong></span><a
                  href="#toc" data-custom-class="link"><span
                  style="font-size: 15px;"><strong>here</strong></span></a><span style="font-size: 15px;"><strong>&nbsp;to go directly to our table of contents.</strong></span>
              </div>

              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with __________ and the Services, the choices you make, and the products and features you use. Click&nbsp;</span><a
                  href="#personalinfo" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">&nbsp;to learn more.</span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.</span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong>Do you receive any information from third parties?</strong> We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Click&nbsp;</span><a
                  href="#othersources" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">&nbsp;to learn more.</span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span
                  style="font-size: 15px;"><strong>How do you process my information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click&nbsp;</span><a
                  href="#infouse" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">&nbsp;to learn more.</span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Click&nbsp;</span><a
                  href="#whoshare" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">&nbsp;to learn more.</span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click&nbsp;</span><a
                  href="#privacyrights" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">&nbsp;to learn more.</span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span
                  style="font-size: 15px;"><strong>How do I exercise my rights?</strong> The easiest way to exercise your rights is by filling out our data subject request form available </span><a
                  href="https://app.termly.io/notify/be3815ed-d75c-43ad-ba6e-eae1fc834abf" target="_blank"
                  rel="noopener noreferrer" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;">Want to learn more about what  does with any information we collect? Click&nbsp;</span><a
                  href="#toc" data-custom-class="link"><span style="font-size: 15px;">here</span></a><span
                  style="font-size: 15px;">&nbsp;to review the notice in full.</span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="toc" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span
                  style="color: #000000;"><strong>TABLE OF CONTENTS</strong></span></span></span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infocollect"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">1. WHAT INFORMATION DO WE COLLECT?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infouse"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">2. HOW DO WE PROCESS YOUR INFORMATION?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><a
                  href="#whoshare"
                  data-custom-class="link">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#cookies"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#sociallogins"
                                                                               data-custom-class="link"><span
                  style="color: #595959;"><span style="color: #595959;"><span style="color: #595959;">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></span></span></a></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#intltransfers"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#inforetain"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infominors"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><a
                  href="#privacyrights" data-custom-class="link">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#DNT"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#caresidents"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#policyupdates"
                                                                               data-custom-class="link"><span
                  style="color: #595959;">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
              <div style="line-height: 1.5;"><a href="#contact" data-custom-class="link"><span
                  style="color: #595959; font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a>
              </div>
              <div style="line-height: 1.5;"><a href="#request" data-custom-class="link"><span style="color: #595959;">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="infocollect" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="personalinfo" style="line-height: 1.5;"><span style="color: #000000;"
                                                                     data-custom-class="heading_2"><span
                  style="font-size: 15px;"><strong>Personal information you disclose to us</strong></span></span></div>
              <div>
                <div>&nbsp;</div>
                <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><strong>In Short:</strong></span></span><span
                    style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><strong>&nbsp;</strong>We collect personal information that you provide to us.</span></span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;">We collect personal information that you voluntarily provide to us when you register on the Services,&nbsp;<span
                  style="font-size: 15px;">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="sensitiveinfo" style="line-height: 1.5;"><span style="font-size: 15px;"><strong>Sensitive Information.</strong> We do not process sensitive information.</span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="color: #000000;" data-custom-class="heading_2"><span
                  style="font-size: 15px;"><strong>Information automatically collected</strong></span></span></div>
              <div>
                <div>&nbsp;</div>
                <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><strong>In Short:</strong></span></span><span
                    style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><strong>&nbsp;</strong>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</span></span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;">Like many businesses, we also collect information through cookies and similar technologies. </span></span>
              </div>
              <div id="infouse" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></span></span></span></span></span>
              </div>
              <div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><strong>In Short:&nbsp;</strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span></span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></span></span>
                <div style="line-height: 1.5;">
                  <div style="line-height: 1.5;">
                    <div style="line-height: 1.5;">
                      <div style="line-height: 1.5;">
                        <div style="line-height: 1.5;">
                          <div style="line-height: 1.5;">
                            <div style="line-height: 1.5;">
                              <div style="line-height: 1.5;">
                                <div style="line-height: 1.5;">
                                  <div style="line-height: 1.5;">
                                    <div style="line-height: 1.5;">
                                      <div style="line-height: 1.5;">
                                        <div style="line-height: 1.5;">
                                          <div style="line-height: 1.5;">
                                            <div style="line-height: 1.5;">
                                              <div style="line-height: 1.5;">
                                                <div style="line-height: 1.5;">
                                                  <div style="line-height: 1.5;">
                                                    <div style="line-height: 1.5;">
                                                      <div style="line-height: 1.5;">
                                                        <div style="line-height: 1.5;">
                                                          <div style="line-height: 1.5;">
                                                            <div style="line-height: 1.5;">
                                                              <div style="line-height: 1.5;">&nbsp;</div>
                                                              <div style="line-height: 1.5;">&nbsp;</div>
                                                              <div id="whoshare" style="line-height: 1.5;"><span
                                                                  style="color: #7f7f7f;"><span
                                                                  style="color: #595959; font-size: 15px;"><span
                                                                  style="font-size: 15px; color: #595959;"><span
                                                                  style="font-size: 15px; color: #595959;"><span
                                                                  id="control" style="color: #000000;"><strong>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></span></span></span></span></span>
                                                              </div>
                                                              <div style="line-height: 1.5;">&nbsp;</div>
                                                              <div style="line-height: 1.5;"><span
                                                                  style="font-size: 15px; color: #595959;"><span
                                                                  style="font-size: 15px; color: #595959;"><strong>In Short:</strong>&nbsp;We may share information in specific situations described in this section and/or with the following third parties.</span></span>
                                                              </div>
                                                              <div style="line-height: 1.5;">&nbsp;</div>
                                                              <div style="line-height: 1.5;">&nbsp;</div>
                                                              <div style="line-height: 1.5;"><span
                                                                  style="font-size: 15px;">We may need to share your personal information in the following situations:</span>
                                                              </div>
                                                              <ul>
                                                                <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
                                                                </li>
                                                              </ul>
                                                              <div style="line-height: 1.5;">
                                                                <div style="line-height: 1.5;">&nbsp;</div>
                                                                <ul>
                                                                  <li style="line-height: 1.5;"><span
                                                                      style="font-size: 15px;"><strong>Affiliates.&nbsp;</strong>We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</span>
                                                                  </li>
                                                                </ul>
                                                                <div style="line-height: 1.5;">&nbsp;</div>
                                                                <ul>
                                                                  <li style="line-height: 1.5;"><span
                                                                      style="font-size: 15px;"><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</span>
                                                                  </li>
                                                                </ul>
                                                                <div style="line-height: 1.5;">
                                                                  <div style="line-height: 1.5;">
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="cookies" style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:</strong>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice<span
                                                                        style="color: #595959; font-size: 15px;">.</span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="sociallogins"
                                                                         style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:&nbsp;</strong>If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform. </span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="intltransfers" style="line-height: 1.5;">
                                                                    <span style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:&nbsp;</strong>We may transfer, store, and process your information in countries other than your own.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">Our servers are located in<span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "</span></span><a
                                                                        href="#whoshare" data-custom-class="link"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: #595959;">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></span></a><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">" above), in</span></span><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">&nbsp;and other countries.</span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="inforetain" style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:&nbsp;</strong>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: #595959;"></span></span>.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="infominors" style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:</strong>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span
                                                                        style="color: #595959; font-size: 15px;"></span>.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="privacyrights" style="line-height: 1.5;">
                                                                    <span style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:</strong>&nbsp;<span
                                                                        style="color: #595959;">&nbsp;</span>You may review, change, or terminate your account at any time.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;">&nbsp;</span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: #595959;"><span
                                                                        style="color: #3030f1;"><a
                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                        target="_blank" rel="noopener noreferrer"
                                                                        data-custom-class="link"><span
                                                                        style="font-size: 15px;">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span>.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">If you are located in Switzerland, the contact details for the data protection authorities are available here: <span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: #595959;"><span
                                                                        style="color: #3030f1;"><span
                                                                        style="font-size: 15px;"><a
                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                        target="_blank" rel="noopener noreferrer"
                                                                        data-custom-class="link">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span>.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="withdrawconsent" style="line-height: 1.5;">
                                                                    <span style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "</span></span><a
                                                                        href="#contact" data-custom-class="link"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></a><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">" below.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;">However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><strong>Account Information</strong></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;">If you would at any time like to review or change the information in your account or terminate your account, you can:</span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="DNT" style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="caresidents"
                                                                         style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:&nbsp;</strong>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="policyupdates" style="line-height: 1.5;">
                                                                    <span style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="contact" style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">If you have questions or comments about this notice, you may <span
                                                                        style="color: #595959; font-size: 15px;">email us at </span><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">&nbsp;or by post to:</span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px;"><span
                                                                        style="color: #595959;"><span
                                                                        style="color: #595959;"></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"></span></div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"></span></div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div id="request" style="line-height: 1.5;"><span
                                                                        style="color: #7f7f7f;"><span
                                                                        style="color: #595959; font-size: 15px;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        id="control" style="color: #000000;"><strong>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></span></span></span></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">&nbsp;</div>
                                                                    <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: #595959;"><span
                                                                        style="font-size: 15px; color: #595959;">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking&nbsp;<span
                                                                        style="color: #3030f1;"><span
                                                                        style="font-size: 15px;"><a
                                                                        href="https://app.termly.io/notify/be3815ed-d75c-43ad-ba6e-eae1fc834abf"
                                                                        target="_blank" rel="noopener noreferrer"
                                                                        data-custom-class="link">here</a></span></span>.</span></span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacypolicy"
}
</script>

<style scoped lang="scss">
.privacy {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family;

  .card-privacy {
    background-color: #FFFFFF;
    max-width: 1200px;
    padding: 40px;

  }

}
</style>
