import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard/AdminDashboard";
import Login from "@/components/from/login";
import Middleware from "@/middleware/index";
import changePassword from "@/views/changePassword/changePassword";
// import store from "@/store/index"
import AccessDenied from "@/components/AccessDenied";
import PrivacyPolicy from "../views/PrivacyPolicy";
import ResetPassword from "@/views/ForgotPassword/ResetPassword";
import VerifyCode from "@/views/ForgotPassword/VerifyCode";
import confirmResetPassword from "@/views/ForgotPassword/confirmResetPassword";
import ForgotPasswordSuccess from "@/views/ForgotPassword/ForgotPasswordSuccess";

Vue.use(VueRouter);
const routes = [
  // {
  //   path: '/',
  //   name: 'App',
  //   component: App
  // },
  // {
  //   path: '/google/callback',
  //   beforeEnter: (to, from, next) => {
  //     axios.get(`/api/google/callback${to.hash}`)
  //       .then(response => {
  //         next('/')
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   }
  // },
  {
    path: "/job-title",
    component: () =>
      import(
        /* webpackChunkName: "JobTitle" */ "../views/MainCompany/JobTitle/job-title"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "job.title.index",
        component: () =>
          import(
            /* webpackChunkName: "JobTitleIndex" */ "../views/MainCompany/JobTitle/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "job.title.create",
        component: () =>
          import(
            /* webpackChunkName: "BranchCreate" */ "../components/JobTitle/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/education-level",
    component: () =>
      import(
        /* webpackChunkName: "JobTitle" */ "../views/MainCompany/EducationLevel/education-level"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "education.level.index",
        component: () =>
          import(
            /* webpackChunkName: "JobTitleIndex" */ "../views/MainCompany/EducationLevel/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "education.level.create",
        component: () =>
          import(
            /* webpackChunkName: "BranchCreate" */ "../components/EducationLevel/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/working-years",
    component: () =>
      import(
        /* webpackChunkName: "JobTitle" */ "../views/MainCompany/WorkingYears/working-years"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "working.year.index",
        component: () =>
          import(
            /* webpackChunkName: "JobTitleIndex" */ "../views/MainCompany/WorkingYears/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "working.year.create",
        component: () =>
          import(
            /* webpackChunkName: "BranchCreate" */ "../components/WorkingYears/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/auth/:token/:user_id",
    name: "auth.with.token",
    component: () =>
      import(
        /* webpackChunkName: "CompanyCreate" */ "@/views/MainEmployees/auth"
      ),
    meta: {
      layout: "applayout"
    }
  },
  {
    path: "/reset-password",
    name: "reset.password.index",
    component: ResetPassword,
    meta: {
      layout: "applayout"
    }
  },
  {
    path: "/reset-password-success",
    name: "ForgotPasswordSuccess",
    component: ForgotPasswordSuccess,
    meta: {
      layout: "applayout"
    }
  },
  {
    path: "/reset-new-password",
    name: "confirmResetPassword",
    component: confirmResetPassword,
    meta: {
      layout: "applayout"
    }
  },

  {
    path: "/verify-code",
    name: "verify.code.index",
    component: VerifyCode,
    meta: {
      layout: "applayout"
    }
  },

  {
    path: "/privacy-policy",
    name: "privacy.policy",
    component: PrivacyPolicy,
    meta: {
      layout: "applayout"
    }
  },
  {
    path: "/403/access-denied",
    name: "isNotAroundPermission",
    component: AccessDenied,
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/MainCompany/test/index"),
    meta: {
      middleware: [Middleware.auth]
    }
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      layout: "default"
    }
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: changePassword,
    meta: {
      layout: "default"
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
  },
  {
    path: "/company-dashboard",
    name: "CompanyDashboard",
    component: () => import("@/views/Dashboard/CompanyDashboard"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
  },
  {
    path: "/company",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "../views/MainAdmin/Company/company"
      ),
    meta: {
      middleware: [Middleware.auth],
      requiredAuth: true,
      layout: "admin",
      menu: "users"
    },
    children: [
      {
        path: "",
        name: "company.index",
        component: () =>
          import(
            /* webpackChunkName: "companyIndex" */ "../views/MainAdmin/Company/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "company.create",
        component: () =>
          import(
            /* webpackChunkName: "CompanyCreate" */ "../components/company/from/CreateCompany"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "edit",
        name: "company.edit",
        component: () =>
          import(
            /* webpackChunkName: "CompanyEdit" */ "../components/company/from/EditCompany"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "user/detail/:company_id",
        name: "company.user.detail",
        component: () =>
          import(
            /* webpackChunkName: "Employee.edit" */ "../components/company/from/UserDetail"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/package",
    component: () =>
      import(
        /* webpackChunkName: "package" */ "../views/MainAdmin/Package/package"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "package.index",
        component: () =>
          import(
            /* webpackChunkName: "packageIndex" */ "../views/MainAdmin/Package/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "package.create",
        component: () =>
          import(
            /* webpackChunkName: "PackageCreate" */ "../components/Package/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/tax",
    component: () =>
      import(/* webpackChunkName: "Tax" */ "../views/MainAdmin/Tax/Tax"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "tax.index",
        component: () =>
          import(
            /* webpackChunkName: "TaxIndex" */ "../views/MainAdmin/Tax/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "tax.create",
        component: () =>
          import(
            /* webpackChunkName: "TaxCreate" */ "../components/Tax/CreateTax"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/social_security",
    component: () =>
      import(
        /* webpackChunkName: "Social" */ "../views/MainAdmin/SocialSecurity/socialSecurity"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "social_security.index",
        component: () =>
          import(
            /* webpackChunkName: "SocialIndex" */ "../views/MainAdmin/SocialSecurity/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "social_security.create",
        component: () =>
          import(
            /* webpackChunkName: "SocialCreate" */ "../components/SocialSecurity/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee",
    component: () =>
      import(
        /* webpackChunkName: "EmployeeIndex" */ "../views/MainCompany/Employee/employee"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "employee.index",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeIndex" */ "../views/MainCompany/Employee/EmployeeTabIndex"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "employee.create",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeCreate" */ "../components/employee/from/CreateEmployee"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "import",
        name: "employee.importData",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeCreate" */ "../views/MainCompany/Employee/ImportData"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "edit/:employee_id",
        name: "employee.edit",
        component: () =>
          import(
            /* webpackChunkName: "Employee.edit" */ "../components/employee/from/EditEmployee"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee-contract",
    component: () =>
      import(
        /* webpackChunkName: "EmployeeIndex" */ "../views/MainCompany/EmployeeContract/employee_contract"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "employee_contract.index",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeIndex" */ "../views/MainCompany/EmployeeContract/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "contract-expire",
        name: "employee_contract.expire",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeCreate" */ "../views/MainCompany/EmployeeContract/contract_expire"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee/holiday",
    component: () =>
      import(
        /* webpackChunkName: "Social" */ "../views/MainCompany/EmployeeHoliday/employeeHoliday"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "emp.leave.index",
        component: () =>
          import(
            /* webpackChunkName: "SocialIndex" */ "../views/MainCompany/EmployeeHoliday/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee/scan-point",
    component: () =>
      import(
        /* webpackChunkName: "Department" */ "../views/MainCompany/ScanPoint/ScanPoint"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "scan_point.index",
        component: () =>
          import(
            /* webpackChunkName: "Department Index" */ "../views/MainCompany/ScanPoint/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "add/:employee_id",
        name: "scan_point.add",
        component: () =>
          import(
            /* webpackChunkName: "Department Index" */ "../views/MainCompany/ScanPoint/layouts/addNew"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee/scan-camera",
    component: () =>
      import(
        /* webpackChunkName: "Department" */ "../views/MainCompany/ScanCamera/ScanCamera"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "scan_camera.index",
        component: () =>
          import(
            /* webpackChunkName: "Department Index" */ "../views/MainCompany/ScanCamera/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "scan_camera.create",
        component: () =>
          import(
            /* webpackChunkName: "Department Index" */ "../views/MainCompany/ScanCamera/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee-level",
    component: () =>
      import(
        /* webpackChunkName: "EmployeeLevel" */ "../views/MainCompany/EmployeeLevel/employeeLevel"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "employeeLevel.index",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeLevelIndex" */ "../views/MainCompany/EmployeeLevel/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "employeeLevel.create",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeLevelCreate" */ "../components/EmployeeLevel/createEmployeeLevel"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }

      // {
      //     path: 'edit',
      //     name: 'employee.edit',
      //     component: () => import(/* webpackChunkName: "Employee.edit" */ '../components/employee/from/EditEmployee'),
      //     meta: {
      //         middleware: [Middleware.auth],
      //         layout: "admin"
      //     },
      // },
    ]
  },
  {
    path: "/business_type",
    component: () =>
      import(
        /* webpackChunkName: "BusinessType" */ "../views/MainAdmin/BusinessType/BusinessType"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "businessType.index",
        component: () =>
          import(
            /* webpackChunkName: "BusinessIndex" */ "../views/MainAdmin/BusinessType/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "businessType.create",
        component: () =>
          import(
            /* webpackChunkName: "BusinessEdit" */ "../components/businessType/CreateBusinessType"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "edit",
        name: "businessType.edit",
        component: () =>
          import(
            /* webpackChunkName: "BusinessEdit" */ "../components/businessType/EditBusinessType"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/plan",
    component: () =>
      import(/* webpackChunkName: "Plan" */ "../views/MainAdmin/Plan/plan"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "plan.index",
        component: () =>
          import(
            /* webpackChunkName: "PlanIndex" */ "../views/MainAdmin/Plan/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "plan.create",
        component: () =>
          import(
            /* webpackChunkName: "PlanCreate" */ "../components/plan/createPlan"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/promotion_plan",
    component: () =>
      import(
        /* webpackChunkName: "PromotionPlan" */ "../views/MainAdmin/PromotionPlan/promotionPlan"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: ":plan_id",
        name: "promotionPlan.index",
        component: () =>
          import(
            /* webpackChunkName: "PromotionPlanIndex" */ "../views/MainAdmin/PromotionPlan/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: ":plan_id/create",
        name: "promotionPlan.create",
        component: () =>
          import(
            /* webpackChunkName: "PromotionPlanCreate" */ "../components/promotionPlan/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/position",
    component: () =>
      import(
        /*webpackChunkName:"Position"*/ "../views/MainCompany/Position/Position"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "position.index",
        component: () =>
          import(
            /* webpackChunkName: "PositionIndex" */ "../views/MainCompany/Position/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "position.create",
        component: () =>
          import(
            /* webpackChunkName: "PositionCreate" */ "../components/position/from/CreatePosition"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee_position",
    component: () =>
      import(
        /*webpackChunkName:"EmployeePosition"*/ "../components/position/employee/EmployeePosition"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: ":position_id",
        name: "positionEmployee.index",
        component: () =>
          import(
            /* webpackChunkName: "PositionEmployeeIndex" */ "../components/position/employee/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/currency",
    component: () =>
      import(
        /* webpackChunkName: "Currency" */ "../views/MainAdmin/Currency/Currency"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "currency.index",
        component: () =>
          import(
            /* webpackChunkName: "CurrencyIndex" */ "../views/MainAdmin/Currency/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "currency.create",
        component: () =>
          import(
            /* webpackChunkName: "CurrencyCreate" */ "../components/currency/from/CreateCurrency"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: ":id/edit",
        name: "currency.edit",
        component: () =>
          import(
            /* webpackChunkName: "CurrencyEdit" */ "../components/currency/from/EditCurrency"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/profile",
    name: "profile.employee",
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../views/MainEmployees/EmployeeProfiles/Profile"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
    // children: [
    //     {
    //         path: '',
    //         name: 'profile.employee',
    //         component: () => import(/* webpackChunkName: "EmployeeProfile" */ '../views/Profile/EmployeeProfile'),
    //         meta: {
    //             middleware: [Middleware.auth]
    //         },
    //     },
    // ]
  },
  {
    path: "/company-log",
    component: () =>
      import(
        /*webpackChunkName: "CompanyLog"*/ "../views/MainCompany/CompanyLog/companyLog"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "company.log",
        component: () =>
          import(
            /* webpackChunkName: "CompanyLogIndex" */ "../views/MainCompany/CompanyLog/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/export-excel",
    component: () =>
      import(
        /*webpackChunkName: "CompanyLog"*/ "../views/MainCompany/Exports/export"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "company.tracking-export-excel",
        component: () =>
          import(
            /* webpackChunkName: "CompanyLogIndex" */ "../views/MainCompany/Exports/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/admin-log",
    component: () =>
      import(
        /*webpackChunkName: "adminLog"*/ "../views/MainAdmin/AdminLog/Adminlog"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "admin.log",
        component: () =>
          import(
            /* webpackChunkName: "adminLogIndex" */ "../views/MainAdmin/AdminLog/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/company-profile",
    name: "company.profile",
    component: () =>
      import(
        /*webpackChunkName:"CompanyProfile"*/ "../views/MainCompany/Profile/CompanyProfile"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
  },
  {
    path: "/company-setting",
    name: "company.setting",
    component: () =>
      import(
        /*webpackChunkName:"CompanyProfile"*/ "../views/MainCompany/Setting/companySetting"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
  },
  {
    path: "/company-user",
    component: () =>
      import(
        /* webpackChunkName: "companyUser" */ "../views/MainCompany/CompanyUser/CompanyUser"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "companyUser.index",
        component: () =>
          import(
            /* webpackChunkName: "companyUserIndex" */ "../views/MainCompany/CompanyUser/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "companyUser.create",
        component: () =>
          import(
            /* webpackChunkName: "companyUserIndex" */ "../components/companyUser/from/createCompanyUser"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/contact_employee",
    component: () =>
      import(
        /* webpackChunkName: "contact_employee" */ "../views/MainEmployees/Contact/Contact"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "contact.index",
        component: () =>
          import(
            /* webpackChunkName: "contact_Index" */ "../views/MainEmployees/Contact/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "contact.create",
        component: () =>
          import(
            /* webpackChunkName: "contact_Create" */ "../components/employeeContact/from/createContact"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/restDay",
    component: () =>
      import(
        /* webpackChunkName: "RestDay" */ "../views/MainCompany/RestDay/RestDay"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "restDay.index",
        component: () =>
          import(
            /* webpackChunkName: "RestDayIndex" */ "../views/MainCompany/RestDay/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "restDay.create",
        component: () =>
          import(
            /* webpackChunkName: "RestDayCreate" */ "../components/restDay/createRestDay"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/disable_rest_day_employee",
    component: () =>
      import(
        /* webpackChunkName: "EmployeeRestDay" */ "../views/MainCompany/DisableRestDayEmployee/disableRestDayEmployee"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: ":holiday_id",
        name: "disableRestDayEmployee.index",
        component: () =>
          import(
            /* webpackChunkName: "RestDayIndex" */ "../views/MainCompany/DisableRestDayEmployee/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: ":holiday_id",
        name: "disableRestDayEmployee.create",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeRestDayCreate" */ "../components/DisableRestDayEmployee/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/schedule",
    component: () =>
      import(
        /* webpackChunkName: "Schedule" */ "../views/MainCompany/CheckInSchedule/checkInSchedule"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "schedule.index",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleIndex" */ "../views/MainCompany/CheckInSchedule/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "schedule.create",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleCreate" */ "../components/CheckInSchedule/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/schedule_detail",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleDetail" */ "../views/MainCompany/ScheduleDetail/scheduleDetail"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: ":schedule_id",
        name: "scheduleDetail.index",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleDetailIndex" */ "../views/MainCompany/ScheduleDetail/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: ":schedule_id/create",
        name: "scheduleDetail.create",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleDetailCreate" */ "../components/ScheduleDetail/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: ":schedule_id/check-scan/:schedule_detail_id",
        name: "checkScan.index",
        component: () =>
          import(
            /* webpackChunkName: "checkScan" */ "../components/CheckedScan/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: ":schedule_id/create_break_time",
        name: "scheduleDetail.create_break_time",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleDetailIndex" */ "../views/MainCompany/ScheduleDetail/BreakTime/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/schedule_attract_employee",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleAttractEmployee" */ "../views/MainCompany/scheduleAttractEmployee/scheduleAttractEmployee"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: ":schedule_id",
        name: "scheduleAttractEmployee.index",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleAttractEmployeeIndex" */ "../views/MainCompany/scheduleAttractEmployee/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: ":schedule_id/create",
        name: "scheduleAttractEmployee.create",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleAttractEmployeeCreate" */ "../components/scheduleAttractEmployee/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee-roster",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleAttractEmployee" */ "../views/MainCompany/EmpoyeeRoster/EmpoyeeRoster"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "employee.roster.index",
        component: () => import("@/views/MainCompany/EmpoyeeRoster/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "add/employee",
        name: "roster.add.employee",
        component: () => import("@/components/EmployeeRoster/addEmployee"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/schedule_attract_position",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleAttractPosition" */ "../views/MainCompany/scheduleAttractPosition/scheduleAttractPosition"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: ":schedule_id",
        name: "scheduleAttractPosition.index",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleAttractPositionIndex" */ "../views/MainCompany/scheduleAttractPosition/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: ":schedule_id/create",
        name: "scheduleAttractPosition.create",
        component: () =>
          import(
            /* webpackChunkName: "ScheduleAttractPositionCreate" */ "../components/scheduleAttractPosition/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/all-zone",
    component: () =>
      import(
        /* webpackChunkName: "allZone" */ "../views/MainCompany/AllZone/allClockZone"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "/all-zone",
        name: "allZone.index",
        component: () =>
          import(
            /* webpackChunkName: "AllZoneIndex" */ "../views/MainCompany/AllZone/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "gps/:zone_type",
        name: "Gps.index",
        component: () =>
          import(
            /* webpackChunkName: "Gps" */ "../components/clockInZone/GPS/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/branch",
    component: () =>
      import(
        /* webpackChunkName: "Education_employee" */ "../views/MainCompany/Branch/branch"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "branch.index",
        component: () =>
          import(
            /* webpackChunkName: "BranchIndex" */ "../views/MainCompany/Branch/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "branch.create",
        component: () =>
          import(
            /* webpackChunkName: "BranchCreate" */ "../components/Branch/CreateBranch"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/benefit",
    component: () =>
      import(
        /* webpackChunkName: "Benefit" */ "../views/MainCompany/Benefit/Benefit"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "benefit.index",
        component: () =>
          import(
            /* webpackChunkName: "BenefitIndex" */ "../views/MainCompany/Benefit/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "benefit.create",
        component: () =>
          import(
            /* webpackChunkName: "BenefitCreate" */ "../components/Benefit/CreateBenefit"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/deduction",
    component: () =>
      import(
        /* webpackChunkName: "Deduction" */ "../views/MainCompany/Deduction/Deduction"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "deduction.index",
        component: () =>
          import(
            /* webpackChunkName: "Deduction Index" */ "../views/MainCompany/Deduction/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "deduction.create",
        component: () =>
          import(
            /* webpackChunkName: "Deduction Create" */ "../components/Deduction/CreateDeduction"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/manage-resume",
    component: () =>
      import(
        /* webpackChunkName: "Resume" */ "../components/employee/manageResume/index"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "employee/:employee_id",
        name: "manageResume.index",
        component: () =>
          import(
            /* webpackChunkName: "ResumeIndex" */ "../components/employee/manageResume/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee",
    component: () =>
      import(
        /* webpackChunkName: "Education_employee" */ "../views/MainCompany/Education/Education"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "education/:employee_id",
        name: "education.index",
        component: () =>
          import(
            /* webpackChunkName: "Education_Index" */ "../views/MainCompany/Education/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create/:employee_id",
        name: "education.create",
        component: () =>
          import(
            /* webpackChunkName: "Education_create" */ "../components/education/createEducation"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee",
    component: () =>
      import(
        /* webpackChunkName: "skill_employee" */ "../views/MainCompany/Skill/Skill"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "skill/:employee_id",
        name: "skill.index",
        component: () =>
          import(
            /* webpackChunkName: "skill_Index" */ "../views/MainCompany/Skill/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create/:employee_id",
        name: "skill.create",
        component: () =>
          import(
            /* webpackChunkName: "skill_create" */ "../components/skill/createSkill"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee",
    component: () =>
      import(
        /* webpackChunkName: "experiences_employee" */ "../views/MainCompany/Experiences/Experiences"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "experiences/:employee_id",
        name: "experiences.index",
        component: () =>
          import(
            /* webpackChunkName: "experiences_Index" */ "../views/MainCompany/Experiences/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create/:employee_id",
        name: "experiences.create",
        component: () =>
          import(
            /* webpackChunkName: "experiences_create" */ "../components/experiences/createExperiences"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/invoice",
    component: () =>
      import(
        /* webpackChunkName: "Invoice" */ "../views/MainAdmin/Invoice/invoice"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "invoice.index",
        component: () =>
          import(
            /* webpackChunkName: "InvoiceIndex" */ "../views/MainAdmin/Invoice/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
      // {
      //     path: 'create',
      //     name: 'benefit.create',
      //     component: () => import(/* webpackChunkName: "BenefitCreate" */ '../components/Benefit/CreateBenefit'),
      //     meta: {
      //         middleware: [Middleware.auth],
      //         layout: "admin"
      //     },
      // },
    ]
  },
  {
    path: "/payrolls-item",
    component: () =>
      import(
        /* webpackChunkName: "PayrollsItem" */ "../views/MainCompany/PayrollsItem/Index"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "payrolls-item.index",
        component: () =>
          import(
            /* webpackChunkName: "PayrollsItem Index" */ "../views/MainCompany/PayrollsItem/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
      // {
      //     path: 'create',
      //     name: 'benefit.create',
      //     component: () => import(/* webpackChunkName: "BenefitCreate" */ '../components/Benefit/CreateBenefit'),
      //     meta: {
      //         middleware: [Middleware.auth],
      //         layout: "admin"
      //     },
      // },
    ]
  },
  {
    path: "/adjustments-item",
    component: () =>
      import(
        /* webpackChunkName: "PayrollsItem" */ "../views/MainCompany/Adjustment/adjustment"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "adjustment-item.index",
        component: () =>
          import(
            /* webpackChunkName: "PayrollsItem Index" */ "../views/MainCompany/Adjustment/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/late",
    component: () =>
      import(/* webpackChunkName: "Late" */ "../views/MainCompany/Late/Late"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "late.index",
        component: () =>
          import(
            /* webpackChunkName: "LateIndex" */ "../views/MainCompany/Late/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "late.create",
        component: () =>
          import(
            /* webpackChunkName: "LateCreate" */ "../components/Late/CreateLate"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/absence",
    component: () =>
      import(
        /* webpackChunkName: "Absence" */ "../views/MainCompany/Absence/Absence"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "absence.index",
        component: () =>
          import(
            /* webpackChunkName: "AbsenceIndex" */ "../views/MainCompany/Absence/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "absence.create",
        component: () =>
          import(
            /* webpackChunkName: "AbsenceCreate" */ "../components/Absence/CreateAbsence"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/payrolls/:payroll_id/:qty_day_work/:qty_hour_work",
    component: () =>
      import(
        /* webpackChunkName: "Payrolls" */ "../views/MainCompany/PayrollsItem/Payrolls/Index"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "payrolls.index",
        component: () =>
          import(
            /* webpackChunkName: "Payrolls" */ "../views/MainCompany/PayrollsItem/Payrolls/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
      // {
      //     path: 'create',
      //     name: 'benefit.create',
      //     component: () => import(/* webpackChunkName: "BenefitCreate" */ '../components/Benefit/CreateBenefit'),
      //     meta: {
      //         middleware: [Middleware.auth],
      //         layout: "admin"
      //     },
      // },
    ]
  },
  {
    path: "/admin-user",
    component: () =>
      import(
        /* webpackChunkName: "userAdmin" */ "../views/MainAdmin/UserAdmin/userAdmin"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "userAdmin.index",
        component: () =>
          import(
            /* webpackChunkName: "userAdmin Index" */ "../views/MainAdmin/UserAdmin/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "userAdmin.create",
        component: () =>
          import(
            /* webpackChunkName: "userAdminCreate" */ "../views/MainAdmin/UserAdmin/form/createUserAdmin"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/company/product",
    component: () =>
      import(
        /* webpackChunkName: "userAdmin" */ "../views/MainAdmin/CompanyProduct/companyProduct"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "company_product.index",
        component: () =>
          import(
            /* webpackChunkName: "userAdmin Index" */ "../views/MainAdmin/CompanyProduct/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "company_product.create",
        component: () =>
          import(
            /* webpackChunkName: "userAdminCreate" */ "../views/MainAdmin/CompanyProduct/form/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/company/project",
    component: () =>
      import(
        /* webpackChunkName: "userAdmin" */ "../views/MainAdmin/CompanyProject/companyProject"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "company_project.index",
        component: () =>
          import(
            /* webpackChunkName: "userAdmin Index" */ "../views/MainAdmin/CompanyProject/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "company_project.create",
        component: () =>
          import(
            /* webpackChunkName: "userAdminCreate" */ "../views/MainAdmin/CompanyProject/form/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/company/project-income",
    component: () =>
      import(
        /* webpackChunkName: "userAdmin" */ "../views/MainAdmin/CompanyProjectIncome/companyProjectIncome"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "company_project_income.index",
        component: () =>
          import(
            /* webpackChunkName: "userAdmin Index" */ "../views/MainAdmin/CompanyProjectIncome/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "company_project_income.create",
        component: () =>
          import(
            /* webpackChunkName: "userAdminCreate" */ "../views/MainAdmin/CompanyProjectIncome/form/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/company/expenses",
    component: () =>
      import(
        /* webpackChunkName: "userAdmin" */ "../views/MainAdmin/CompanyProjectExpenses/companyProjectExpenses"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "company_expense.index",
        component: () =>
          import(
            /* webpackChunkName: "userAdmin Index" */ "../views/MainAdmin/CompanyProjectExpenses/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create-type",
        name: "company_expense.type",
        component: () =>
          import(
            /* webpackChunkName: "userAdminCreate" */ "../views/MainAdmin/CompanyProjectExpenses/type/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/project-income/summary",
    component: () =>
      import(
        /* webpackChunkName: "userAdmin" */ "../views/MainAdmin/CompanyProjectIncomeSummary/index"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "summary.index",
        component: () =>
          import(
            /* webpackChunkName: "userAdmin Index" */ "../views/MainAdmin/CompanyProjectIncomeSummary/layouts/home"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "summary.create",
        component: () =>
          import(
            /* webpackChunkName: "userAdminCreate" */ "../views/MainAdmin/CompanyProjectIncome/form/create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "*",
    redirect: "/"
  },

  {
    path: "/authorize-document-user",
    component: () =>
      import(
        /* webpackChunkName: "authorize" */ "../views/MainCompany/AuthorizeDocumentUser/AuthorizeDocumentUser"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "authorize.document.index",
        component: () =>
          import(
            /* webpackChunkName: "authorize Index" */ "../views/MainCompany/AuthorizeDocumentUser/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },

      {
        path: "create",
        name: "authorize.document.create",
        component: () =>
          import(
            /* webpackChunkName: "authorize Create" */ "../views/MainCompany/AuthorizeDocumentUser/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/document",
    component: () =>
      import(
        /* webpackChunkName: "Document" */ "../views/MainCompany/Document/document"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "document.index",
        component: () =>
          import(
            /* webpackChunkName: "Document Index" */ "../views/MainCompany/Document/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "document-type",
        name: "document.table",
        component: () =>
          import(
            /* webpackChunkName: "Document Table" */ "../views/MainCompany/Document/documentTable"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "document.create",
        component: () =>
          import(
            /* webpackChunkName: "Document Create" */ "../views/MainCompany/Document/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/exchange",
    component: () =>
      import(
        /* webpackChunkName: "Exchange" */ "../views/MainCompany/Exchange/Exchange"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "exchange.index",
        component: () =>
          import(
            /* webpackChunkName: "Exchange Index" */ "../views/MainCompany/Exchange/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "exchange.create",
        component: () =>
          import(
            /* webpackChunkName: "Exchange Create" */ "../views/MainCompany/Exchange/form/CreateExchange"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/holiday-type",
    component: () =>
      import(
        /* webpackChunkName: "HolidayType" */ "../views/MainCompany/HolidayType/holidayType"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "holiday-type.index",
        component: () =>
          import(
            /* webpackChunkName: "HolidayType Index" */ "../views/MainCompany/HolidayType/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "holiday-type.create",
        component: () =>
          import(
            /* webpackChunkName: "Holiday Create" */ "../views/MainCompany/HolidayType/form/CreateHolidayType"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/vacation",
    component: () =>
      import(
        /* webpackChunkName: "Vacation" */ "../views/MainCompany/Vacation/Vacation"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "vacation.index",
        component: () =>
          import(
            /* webpackChunkName: "Vacation Index" */ "../views/MainCompany/Vacation/Index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "vacation.create",
        component: () =>
          import(
            /* webpackChunkName: "Vacation Create" */ "../views/MainCompany/Vacation/form/Create"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/department",
    component: () =>
      import(
        /* webpackChunkName: "Department" */ "../views/MainCompany/Department/department"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "department.index",
        component: () =>
          import(
            /* webpackChunkName: "Department Index" */ "../views/MainCompany/Department/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "department.create",
        component: () =>
          import(
            /* webpackChunkName: "Department Create" */ "../views/MainCompany/Department/CRUD/CreateDepartment"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/bank",
    component: () =>
      import(/* webpackChunkName: "Bank" */ "../views/MainAdmin/Bank/bank"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "bank.index",
        component: () =>
          import(
            /* webpackChunkName: "Bank Index" */ "../views/MainAdmin/Bank/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "bank.create",
        component: () =>
          import(
            /* webpackChunkName: "Bank Create" */ "../views/MainAdmin/Bank/CRUD/createBank"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/role",
    component: () =>
      import(/* webpackChunkName: "Role" */ "../views/MainAdmin/Role/role"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "role.index",
        component: () =>
          import(
            /* webpackChunkName: "Role Index" */ "../views/MainAdmin/Role/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "role.create",
        component: () =>
          import(
            /* webpackChunkName: "Role Create" */ "../views/MainAdmin/Role/CRUD/CreateRole"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  //permission
  {
    path: "/permission",
    component: () =>
      import(
        /* webpackChunkName: "Permission" */ "../views/MainAdmin/Permission/permission"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },

    children: [
      {
        path: "",
        name: "permission.index",
        component: () =>
          import(
            /* webpackChunkName: "Permission Index" */ "../views/MainAdmin/Permission/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "permission.create",
        component: () =>
          import(
            /* webpackChunkName: "Permission Create" */ "../views/MainAdmin/Permission/CRUD/CreatePermission"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  //Report and Export
  {
    path: "/export",
    component: () =>
      import(
        /* webpackChunkName: "Export" */ "../components/Export/ExportEmployee"
      ),
    name: "export.employee",
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    }
  },
  {
    path: "/device-scan-report",
    component: () =>
      import(/* webpackChunkName: "Absence" */ "../views/Report/DeviceScan"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "deviceScan.index",
        component: () =>
          import(
            /* webpackChunkName: "Absence Index" */ "../views/Report/DeviceScan"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/clock-in/report",
    component: () =>
      import(/* webpackChunkName: "Absence" */ "../views/Report/clock_in_out"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "clock_in_out.index",
        component: () =>
          import(
            /* webpackChunkName: "Absence Index" */ "../views/Report/clock_in_out"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/report/clock-in/correction",
    component: () =>
      import(
        /* webpackChunkName: "Absence" */ "../views/Report/clock_in_out_correction"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "clock_in_out_correction.index",
        component: () =>
          import(
            /* webpackChunkName: "Absence Index" */ "../views/Report/clock_in_out_correction"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/clock-in-outside/report",
    component: () =>
      import(
        /* webpackChunkName: "Absence" */ "../views/Report/clock_in_out_outside"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "clock_in_out_outside.index",
        component: () =>
          import(
            /* webpackChunkName: "Absence Index" */ "../views/Report/clock_in_out_outside"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/emp-tax-report",
    component: () =>
      import(/* webpackChunkName: "Absence" */ "../views/Report/empTax"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "emTaxeport.index",
        component: () =>
          import(
            /* webpackChunkName: "Absence Index" */ "../views/Report/empTax"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/sso-report",
    component: () =>
      import(/* webpackChunkName: "Absence" */ "../views/Report/sso"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "ssoReport.index",
        component: () =>
          import(/* webpackChunkName: "Absence Index" */ "../views/Report/sso"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/todolist-report",
    component: () =>
      import(
        /* webpackChunkName: "Absence" */ "../views/Report/ExportTodoList"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "todolist.index",
        component: () =>
          import(
            /* webpackChunkName: "ExportTodoList Index" */ "../views/Report/ExportTodoList"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/e-approval",
    component: () =>
      import(/* webpackChunkName: "E-approval" */ "../views/Report/EApproval"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "approval.index",
        component: () =>
          import(
            /* webpackChunkName: "E-approval Index" */ "../views/Report/EApproval"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/e-leave/public",
    component: () =>
      import(
        /* webpackChunkName: "E-leave" */ "../views/Report/PublicLeave/public_leave"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "e-leave.public.index",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "../views/Report/PublicLeave/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/e-leave",
    component: () =>
      import(/* webpackChunkName: "E-leave" */ "../views/Report/Leave/leave"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "e-leave.index",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "../views/Report/Leave/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "add",
        name: "e-leave.add.new",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "../views/Report/Leave/add-leave"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "edit/:leave_id",
        name: "e-leave.edit",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "../views/Report/Leave/edit-leave"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/follow-schedule",
    component: () =>
      import(
        /* webpackChunkName: "E-leave" */ "../views/Report/FollowSchedule/reportFollowSchedule.vue"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "follow_schedule.index",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "../views/Report/FollowSchedule/index.vue"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/follow-not-scan",
    component: () =>
      import(
        /* webpackChunkName: "E-leave" */ "../views/Report/FollowNotScan/reportFollowNotScan.vue"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "follow_not_scan.index",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "../views/Report/FollowNotScan/index.vue"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/anouncement",
    component: () =>
      import(
        /* webpackChunkName: "E-leave" */ "@/views/MainCompany/Anouncement/anouncement"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "anouncement.index",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "@/views/MainCompany/Anouncement/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/meeting",
    component: () =>
      import(
        /* webpackChunkName: "E-leave" */ "@/views/MainCompany/Meeting/meeting"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "meeting.index",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "@/views/MainCompany/Meeting/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee-dashboard",
    component: () => import("@/views/MainEmployees/Roster/roster"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "EmployeeDashboard",
        component: () =>
          import(
            /* webpackChunkName: "E-leave Index" */ "@/views/MainEmployees/Roster/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },

  {
    path: "/employee/roster",
    component: () => import("@/views/MainEmployees/Roster/roster"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_roster.index",
        component: () => import("@/views/MainEmployees/Roster/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "request",
        name: "employee_roster.request",
        component: () => import("@/views/MainEmployees/Roster/request"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/employee/request/ot",
    component: () => import("@/views/MainEmployees/OT/employeeOt"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_ot_request.index",
        component: () => import("@/views/MainEmployees/OT/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "create",
        name: "employee_ot_request.create",
        component: () => import("@/views/MainEmployees/OT/create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "edit/:employee_ot_request_id",
        name: "employee_ot_request.update",
        component: () => import("@/views/MainEmployees/OT/update"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },

  {
    path: "/employee/use/ot",
    component: () =>
      import("@/views/MainEmployees/EmployeeUseOT/EmployeeUseOt"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_use_ot.index",
        component: () => import("@/views/MainEmployees/EmployeeUseOT/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/use/ot/add",
        name: "employee_use_ot.create",
        component: () =>
          import("@/views/MainEmployees/EmployeeUseOT/Crud/Create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/use/ot/edit/:employee_ot_use_id",
        name: "employee_use_ot.update",
        component: () =>
          import("@/views/MainEmployees/EmployeeUseOT/Crud/Update"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },

  {
    path: "/employee/approve/ot",
    component: () => import("@/views/MainEmployees/EmployeeApproveOT/Employee"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_approve_ot.index",
        component: () =>
          import("@/views/MainEmployees/EmployeeApproveOT/EmployeeApproveTab"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/employee/approve/use/ot",
    component: () =>
      import("@/views/MainEmployees/EmployeeApproveUseOT/Employee"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_approve_use_ot.index",
        component: () =>
          import(
            "@/views/MainEmployees/EmployeeApproveUseOT/EmployeeApproveUseTab"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/employee/report/ot",
    component: () =>
      import("@/views/MainEmployees/EmployeeReportOT/EmployeeReportOT"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_report_ot.index",
        component: () => import("@/views/MainEmployees/EmployeeReportOT/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/setting/ot",
    component: () => import("@/views/MainCompany/SettingOT/ManageOt"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "setting_ot.index",
        component: () => import("@/views/MainCompany/SettingOT/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "/setting/ot/create",
        name: "setting_ot.create",
        component: () => import("@/views/MainCompany/SettingOT/Crud/Create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "/setting/ot/update",
        name: "setting_ot.update",
        component: () => import("@/views/MainCompany/SettingOT/Crud/Update"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee/verify-code",
    component: () => import("@/views/MainCompany/VerifyCode/verifyCode"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "verify-code.index",
        component: () => import("@/views/MainCompany/VerifyCode/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/change/employee/password",
    component: () =>
      import("@/views/MainCompany/ChangePassword/changePassword"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "change.password.index",
        component: () => import("@/views/MainCompany/ChangePassword/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },

  {
    path: "/employee/ot",
    component: () => import("@/views/MainCompany/OT/employeeOt"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "employee_ot.index",
        component: () => import("@/views/MainCompany/OT/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "employee_ot.create",
        component: () => import("@/views/MainCompany/OT/create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "edit/:employee_ot_id",
        name: "employee_ot.edit",
        component: () => import("@/views/MainCompany/OT/update"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee/using/ot",
    component: () => import("@/views/MainCompany/OTUsing/employeeOt"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "employee_using_ot.index",
        component: () => import("@/views/MainCompany/OTUsing/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      },
      {
        path: "create",
        name: "employee_using_ot.create",
        component: () => import("@/views/MainCompany/OTUsing/create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee/ot/list",
    component: () => import("@/views/Report/OTList/overtimeList"),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "employee_ot_list.index",
        component: () => import("@/views/Report/OTList/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee/e-approve",
    component: () =>
      import("@/views/MainEmployees/EmployeeEApproval/employeeEApproval"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_e_approve.index",
        component: () =>
          import("@/views/MainEmployees/EmployeeEApproval/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/e-approve/add",
        name: "employee_e_approve.create",
        component: () =>
          import("@/views/MainEmployees/EmployeeEApproval/crud/create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/e-approve/update/:id",
        name: "employee_e_approve.update",
        component: () =>
          import("@/views/MainEmployees/EmployeeEApproval/crud/update"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/e-approve/approve",
        name: "employee_e_approve.approve",
        component: () =>
          import("@/views/MainEmployees/EmployeeEApproval/approval"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/employee/scan-time",
    component: () =>
      import(
        /* webpackChunkName: "Department" */ "../views/MainCompany/ScanTime/ScanTime"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "admin"
    },
    children: [
      {
        path: "",
        name: "scan_time.index",
        component: () =>
          import(
            /* webpackChunkName: "Department Index" */ "../views/MainCompany/ScanTime/index"
          ),
        meta: {
          middleware: [Middleware.auth],
          layout: "admin"
        }
      }
    ]
  },
  {
    path: "/employee/task-allocation",
    component: () =>
      import(
        "@/views/MainEmployees/EmployeeTaskAllocation/EmployeeTaskAllocation"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_task_allocation.index",
        component: () =>
          import("@/views/MainEmployees/EmployeeTaskAllocation/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/task-allocation/add",
        name: "employee_task_allocation.add",
        component: () =>
          import("@/views/MainEmployees/EmployeeTaskAllocation/Crud/Create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/task-allocation/mywork",
        name: "employee_task_allocation.mywork",
        component: () =>
          import("@/views/MainEmployees/EmployeeTaskAllocation/MyWork"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/task-allocation/assign",
        name: "employee_task_allocation.assignment",
        component: () =>
          import("@/views/MainEmployees/EmployeeTaskAllocation/AssignTask"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/employee/leave/request",
    component: () =>
      import("@/views/MainEmployees/EmployeeLeave/EmployeeLeave"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_leave.index",
        component: () => import("@/views/MainEmployees/EmployeeLeave/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/leave/lm",
        name: "employee_leave.approve",
        component: () => import("@/views/MainEmployees/EmployeeLeave/Approve"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/leave/hr",
        name: "employee_leave_hr.approve",
        component: () =>
          import("@/views/MainEmployees/EmployeeLeave/HrApprove"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/leave/add",
        name: "employee_leave.add",
        component: () =>
          import("@/views/MainEmployees/EmployeeLeave/Crud/Create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/employee/meeting",
    component: () =>
      import("@/views/MainEmployees/EmployeeMeeting/EmployeeMeeting"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "",
        name: "employee_meeting.index",
        component: () => import("@/views/MainEmployees/EmployeeMeeting/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/meeting/add",
        name: "employee_meeting.add",
        component: () =>
          import("@/views/MainEmployees/EmployeeMeeting/Crud/Create"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "/employee/meeting/history",
        name: "employee_meeting.history",
        component: () =>
          import("@/views/MainEmployees/EmployeeMeeting/History"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/recruitment",
    component: () =>
      import("@/views/Recruitements/RecruitmentRequest/RecruitmentRequest"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "request",
        name: "recruitment.request",
        component: () =>
          import("@/views/Recruitements/RecruitmentRequest/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "request/add",
        name: "recruitment.add",
        component: () =>
          import("@/views/Recruitements/RecruitmentRequest/AddNew"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "request/detail/:id",
        name: "recruitment.detail",
        component: () =>
          import("@/views/Recruitements/RecruitmentRequest/Detail"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "request/edit/:id",
        name: "recruitment.edit",
        component: () =>
          import("@/views/Recruitements/RecruitmentRequest/Edit"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/director/approve/recruitment",
    component: () =>
      import("@/views/Recruitements/DirectorApprove/DirectorApprove"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "/",
        name: "director.approve.recruitment",
        component: () => import("@/views/Recruitements/DirectorApprove/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "detail/:id",
        name: "director.approve.detail",
        component: () => import("@/views/Recruitements/DirectorApprove/Detail"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/hr/approve/recruitment",
    component: () => import("@/views/Recruitements/HrApprove/HrApprove"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "/",
        name: "hr.approve.recruitment",
        component: () => import("@/views/Recruitements/HrApprove/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "detail/:id",
        name: "hr.approve.detail",
        component: () => import("@/views/Recruitements/HrApprove/Detail"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/hr/selection/applicant",
    component: () =>
      import("@/views/Recruitements/SelectionApplicant/SelectionApplicant"),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "/",
        name: "hr.selected.applicant",
        component: () =>
          import("@/views/Recruitements/SelectionApplicant/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "detail/:id",
        name: "hr.selected.applicant.detail",
        component: () =>
          import("@/views/Recruitements/SelectionApplicant/detail"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/hr/selection/applicant/interview",
    component: () =>
      import(
        "@/views/Recruitements/SelectionApplicantInterview/SelectionApplicantInterview"
      ),
    meta: {
      middleware: [Middleware.auth],
      layout: "employee"
    },
    children: [
      {
        path: "/",
        name: "hr.selected.applicant.interview",
        component: () =>
          import("@/views/Recruitements/SelectionApplicantInterview/index"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      },
      {
        path: "detail/:id",
        name: "hr.selected.applicant.interview.detail",
        component: () =>
          import("@/views/Recruitements/SelectionApplicantInterview/detail"),
        meta: {
          middleware: [Middleware.auth],
          layout: "employee"
        }
      }
    ]
  },
  {
    path: "/apply/job/:id",
    name: "apply.job",
    component: () => import("@/views/Recruitements/ApplyJob/apply"),
    meta: {
      layout: "applayout"
    }
  },
  {
    path: "/success/apply/job",
    name: "apply.job.success",
    component: () => import("@/views/Recruitements/ApplyJob/success"),
    meta: {
      layout: "applayout"
    },
    props: (route) => ({
      id: route.query.id,
      name: route.query.name,
      tel: route.query.tel,
      position: route.query.position
    })
  },
  {
    path: "/interview/job/:id",
    name: "interview.job",
    component: () => import("@/views/Recruitements/ApplyJob/Interview"),
    meta: {
      layout: "applayout"
    }
  },
  {
    path: "/confirm/interview",
    name: "confirm.interview",
    component: () => import("@/views/Recruitements/ApplyJob/ConfirmInterview"),
    meta: {
      layout: "applayout"
    },
    props: (route) => ({
      apply_job_id: route.query.apply_job_id,
      phone_number: route.query.phone_number
    })
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0
    };
  }
});

function nextCheck(context, middleware, index) {
  const nextMiddleware = middleware[index];
  if (!nextMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMidd = nextCheck(context, middleware, index + 1);

    nextMiddleware({ ...context, nextMidd });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextCheck(ctx, middleware, 1);
    return middleware[0]({ ...ctx, nextMiddleware });
  }
  return next();
});

// router.beforeEach((to, from, next) => {
//     if (to.meta.requiredAuth) {
//         const authUser = localStorage.getItem("access_token");
//         if (!authUser) {
//             next({name: "Login"});
//         }
//         if (store.state.menus[to.meta.menu]) {
//             const authUserRole = localStorage.getItem("user_role");
//             alert(authUserRole)
//             if (store.state.menus[to.meta.menu].roles.includes((authUserRole || "").toLowerCase())) {
//
//                 next();
//             } else {
//                 // next("/access-denied");
//                 alert("access denied")
//             }
//         }
//     } else {
//         next();
//     }
// });

export default router;
