<template>
  <div class="success-form">
    <div class="form-success">
      <div class="image-top">
        <img :src="require('@/assets/draft.png')" alt="">
      </div>
      <h1>ປ່ຽນລະຫັດຜ່ານສຳເລັດ</h1>
      <div class="button-back" @click="comeBackHome">
        ກັບຄືນ
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordSuccess",
  methods:{
    comeBackHome(){
      this.$router.push({
        name:"Login"
      })
      .catch(()=>{})
    }
  }
}
</script>

<style scoped lang="scss">
.success-form {
  width: 100%;
  height: 100vh;
  background-color: #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-success {
    width: 900px;
    height: 700px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-top {
      img {
        width: 280px;
        height: 280px;
      }
    }

    h1 {
      font-family: $font-family;
      font-size: 2.4rem;
      font-weight: 500;
    }

    .button-back {
      width: 300px;
      height: 60px;
      background-color: #0978EA;
      margin-top: 20px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-family: $font-family;
      font-size: 1.8rem;
      cursor: pointer;
    }
  }
}
</style>
