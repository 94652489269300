<template>
  <div class="reset-password">
    <div class="form-reset-password">
      <div class="top-title">
        <img :src="require('@/assets/email.png')" alt="">
        <h1>ປ້ອນອີເມວທີ່ໃຊ້ເຂົ້າສູ່ລະບົບ</h1>
      </div>
      <div class="form">
        <v-row class="mt-5">
          <v-col cols="12" md="12" lg="12">
            <v-text-field
                outlined
                type="email"
                v-model="item.email"
                :hint="`${server_errors.email}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="12">
            <v-btn style="width: 50%;height: 45px" class="btn-save-change" @click="sendEmail" :loading="btnLoading">
              ຍືນຍັນ
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      btnLoading: false,
      item: {
        email: "",
      },
      server_errors: {
        email: "",
      }
    }
  },
  methods: {
    sendEmail() {
      this.btnLoading = true;
      this.$axios.post(`send-request-verification-code`, this.item).then((res) => {
        if (res.status === 200) {
          this.btnLoading = false;
          this.$router.push({
            name: "verify.code.index"
          }).catch(() => {
          })
        }
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.reset-password {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;


  .form-reset-password {
    width: 700px;
    height: 440px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;

    .top-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
      }

      h1 {
        font-family: $font-family;
        font-size: 1.4rem;
        margin-top: 20px;
      }
    }

    .form {
      width: 100%;
      height: 300px;

    }
  }
}
</style>
