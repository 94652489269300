import axios from "axios";
import store from "@/store/index";

const $axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL
});

const state = {
  personal: {},
  contact: {},
  account: {},
  salary: {},
  employeeHoliday: {},
  employee_id: null,
  employee_leave_id: null
};
const getters = {
  employee_leave_id(state) {
    return state.employee_leave_id;
  },
  employee_id(state) {
    return state.employee_id;
  },
  getPersonal(state) {
    return state.personal;
  },
  getContact(state) {
    return state.contact;
  },
  getSalary(state) {
    return state.salary;
  }
};
const mutations = {
  PERSONAL_ITEM(state, payload) {
    return (state.personal = payload);
  },
  CONTACT_ITEM(state, payload) {
    return (state.contact = payload);
  },
  ACCOUNT_ITEM(state, payload) {
    return (state.account = payload);
  },
  SALARY_ITEM(state, payload) {
    return (state.salary = payload);
  },
  EMPLOYEE_LEAVE_ID(state, payload) {
    return (state.employee_leave_id = payload);
  },
  EMPLOYEE_ID(state, payload) {
    return (state.employee_id = payload);
  },
  EMPLOYEE_HOLIDAY(state, payload) {
    return (state.employeeHoliday = payload);
  }
};

const actions = {
  saveEmployee({ state }) {
    const employee = {
      profile: state.personal.profile ? state.personal.profile : "",
      name: state.personal.name,
      surname: state.personal.surname,
      en_name: state.personal.en_name,
      en_surname: state.personal.en_surname,
      gender: state.personal.gender,
      birth_date: state.personal.birth_date,
      nationality_id: state.personal.nationality_id,
      resumeFile: state.personal.resumeFile ? state.personal.resumeFile : "",
      emp_number: state.personal.emp_number,

      //contact
      district_id: state.contact.district_id,
      village_id: state.contact.village_id,
      contacts: state.contact.contacts ? state.contact.contacts : [],
      card_type: state.contact.card_type,
      card_id: state.contact.card_id,
      emergency_contact: state.contact.emergency_contact,
      relationship: state.contact.relationship,
      //account
      bank_id: state.account.bank_id,
      bank_name: state.account.bank_name,
      bank_account_name: state.account.bank_account_name,
      bank_account: state.account.bank_account,
      email: state.account.email,
      //salary
      base_salary: state.salary.base_salary,
      positionId: state.salary.positionId,
      company_branch_id: state.salary.company_branch_id,
      include_salary_tax: state.salary.include_salary_tax,
      employment_type: state.salary.employment_type,
      start_date: state.salary.start_date,
      end_date: state.salary.end_date,
      benefits: state.salary.benefits ? state.salary.benefits : [],
      level_id: state.salary.level_id,
      finger_scan: state.salary.finger_scan,
      scan_outside: state.salary.scan_outside,
      is_sso: state.salary.is_sso,
      sso_number: state.salary.sso_number,
      is_line_manager: state.salary.is_line_manager,
      company_department_id: state.salary.company_department_id,
      roleId: state.salary.roleId ? state.salary.roleId : [],
      job_title_id: state.salary.job_title_id,
      education_level_id: state.salary.education_level_id,
      is_annual_bonus: state.salary.is_annual_bonus,
      //employee_Holiday
      employee_holidays: state.employeeHoliday.employee_holidays
        ? state.employeeHoliday.employee_holidays
        : []
    };
    return new Promise((resolve, reject) => {
      $axios.defaults.headers.common["Authorization"] =
        "Bearer " + store.getters["User/getToken"];
      $axios
        .post(`company/add-employee`, employee)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
