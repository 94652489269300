<template>
  <div class="section-login">
    <div class="card-login">
      <div class="section-header">
        <div class="img">
          <img src="../../../public/undraw_male_avatar_323b.png" alt="" />
        </div>
      </div>
      <div class="section-content"></div>
      <div class="from-section">
        <h1 class="title-login">ເຂົ້າສູ່ລະບົບ</h1>
        <div class="input-section">
          <h5 class="show-error">{{ $store.getters["User/ShowMsgErrors"] }}</h5>
          <v-text-field
            prepend-inner-icon="mdi-email-edit"
            label="UserName"
            outlined
            dense
            v-model="users.email"
            v-on:keyup.enter="setFocusPass"
          >
          </v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-key"
            v-model="users.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            outlined
            dense
            v-on:keyup.enter="AdminSigIn"
            ref="pass"
          >
          </v-text-field>
          <v-btn
            class="btn-login"
            width="100%"
            @click="AdminSigIn"
            :loading="btnLoading"
          >
            Login
          </v-btn>
          <a @click="resetPassword" class="forgot-password">Forgot Password?</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      showPassword: false,
      users: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      AdminLogin: "User/LoginUser",
    }),
    resetPassword() {
      this.$router
        .push({
          name: "reset.password.index",
        })
        .catch(() => {});
    },
    setFocusPass() {
      this.$refs.pass.$refs.input.focus();
    },

    AdminSigIn() {
      this.btnLoading = true;
      this.AdminLogin(this.users)
        .then(() => {
          setTimeout(() => {
            this.$notification.OpenNotification_Login_Success(
              "top-right",
              "primary",
              9000
            );
          }, 300);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.section-login {
  min-height: 100vh;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-login {
    width: 500px;
    height: 600px;
    background-color: #ffffff;
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 5%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    .section-header {
      flex: 1;
      background-color: #18bbec;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      .img {
        width: 120px;
        height: 120px;
        background-color: rosybrown;
        border-radius: 50%;
        position: absolute;
        top: 20px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }

  .from-section {
    width: 100%;
    height: 400px;
    position: initial;
    background-color: #ffffff;
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 12%);
    bottom: 40px;
    left: 25px;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    .title-login {
      font-family: $font-family;
      font-size: 22px;
      margin-top: 10px;
      font-weight: lighter;
      overflow: hidden;
      height: 40px;
    }

    .input-section {
      margin-top: 50px;
      position: absolute;
      width: 100%;
      height: 350px;
      margin-bottom: 100px;
      padding: 30px 35px;

      .show-error {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-family: $font-family;
        width: 100%;
        height: 20px;
        margin-bottom: 30px;
        color: red;
      }

      .btn-login {
        border: none;
        outline: none;
        height: 40px;
        transition: all 0.125s ease-in;
        background-color: $main-btn-color;
        color: #ffffff;
        text-transform: capitalize;
        box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
        font-size: 16px;
        font-family: $font-family;
        font-weight: normal;
      }
    }
  }
}

.forgot-password {
  font-family: $font-family;
  float: right;
  height: 40px;
  margin-top: 15px;
}
</style>
