<template>
  <div class="main-container-dashboard">
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <div class="dashboard-left">
          <div class="dashboard-left-header">
            <div class="title-left">
              <h1>Hello Gilbert</h1>
              <p>welcome to Admin Dashboard</p>
            </div>
            <div class="date-now">
              {{ currentDateTime }}<span><i class="far fa-calendar"></i></span>
            </div>
          </div>

          <div class="card-left-group">
            <v-row>
              <v-col cols="4" md="4" lg="4">
                <div class="card-item-dashboard">
                  <div class="card-item-dashboard-detail">
                    <h3>50 Employee</h3>
                    <p>Active</p>
                  </div>
                  <div class="card-item-dashboard-icon">
                    <i class="fas fa-user"></i>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" md="4" lg="4">
                <div class="card-item-dashboard">
                  <div class="card-item-dashboard-detail">
                    <h3>Holiday</h3>
                    <p>report</p>
                  </div>
                  <div class="card-item-dashboard-icon">
                    <i class="fas fa-calendar"></i>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" md="4" lg="4">
                <div class="card-item-dashboard">
                  <div class="card-item-dashboard-detail">
                    <h3>Salary</h3>
                    <p>report</p>
                  </div>
                  <div class="card-item-dashboard-icon">
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="chart-group-item">
            <v-row>
              <v-col cols="12" md="6" lg="6">
                <div class="chart-item">
                  <column-chart :data="[['Sun', 32], ['Mon', 46], ['Tue', 28]]"></column-chart>
                </div>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <div class="chart-item">
                  <column-chart :data="[['Sun', 32], ['Mon', 46], ['Tue', 28]]"></column-chart>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateNow: "",
    }
  },
  computed: {
    currentDateTime() {
      const currentTime = this.$helpers.getDateTime();
      return `${currentTime.days} ${currentTime.months} ${currentTime.years}`;
    }
  },
}
</script>

<style scoped lang="scss">

</style>
