<template>
  <v-row justify="center" >
    <v-dialog
        @click="close"
        v-model="dialog"
        persistent
        :fullscreen="fullscreen"
        :max-width="cardWidth">
      <v-card>
        <v-card-actions class="ml-2">
          <icon name="icon-add"/>
          <v-list-item-title class="title-header-form ml-5">
            <span class="title-modalAdd">{{ title }}</span>
          </v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              depressed
              @click="close">
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>

        <slot v-bind="{close}"></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import icon from "@/components/icon"
export default {
  props: {
    title: {
      default: 'Title',
      type: String
    },

    icon:{
      default: 'mdi-close',
      type: String
    },
    cardWidth: {
      default:600,
      type:Number,
    },
    fullscreen: {
      default:false,
      type:Boolean,
    }
  },
  components:{
    icon,
  },
  data(){
    return {
    }
  },
  computed: {
    dialog: {
      set(val){
        this.$store.commit("modalAdd_State", val);
      },
      get(){
        return this.$store.state.modalAddState;
      }
    }
  },
  methods:{
    close(){
      this.dialog = false;
      this.$emit('close');
    }

  }
}
</script>

<style scoped lang="scss">
.title-modalAdd{
  text-transform: capitalize;
  font-family: $font-family;
}
</style>
