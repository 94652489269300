<template>
  <v-app class="grey lighten-4">
    <v-main>
      <div v-if="$route.meta.layout == 'admin'">
        <AdminLayout v-if="!$route.meta.hiddens" />
      </div>
      <div v-if="$route.meta.layout == 'applayout'">
        <AppLayout />
      </div>
      <div v-if="$route.meta.layout == 'employee'">
        <EmpLayout />
      </div>
      <div v-if="$route.meta.layout == 'default'">
        <DefaultLayout />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AdminLayout from "@/views/Layout/AdminLayout";
import DefaultLayout from "@/views/Layout/DefaultLayout";
import EmpLayout from "@/views/Layout/EmpLayout";
import AppLayout from "@/views/Layout/AppLayout";

export default {
  name: "App",
  components: {
    AdminLayout,
    DefaultLayout,
    EmpLayout,
    AppLayout,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.text-primary {
  color: rgb(0, 119, 255) !important;
}

.text-success {
  color: rgb(16, 222, 61) !important;
}

.text-danger {
  color: red !important;
}

.text-warning {
  color: rgb(242, 186, 0) !important;
}

.text-right {
  text-align: right;
}

.text-white {
  color: white !important;
}

.display-flex {
  display: flex;
}

.lfont {
  font-family: $font-family !important;
}

.btn {
  color: white !important;
  font-weight: 800;
}
</style>
