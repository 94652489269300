<template>
  <div class="change-password">
    <div class="card-change-password">
      <div class="icon">
        <i class="fal fa-lock-alt"></i>
      </div>
      <h1>Are you sure change password?</h1>
      <v-row class="mt-5" dense v-if="checkStatusChangePassword">
        <v-col cols="12" md="12" lg="12">
          <v-text-field
              label="Current Password"
              outlined
              v-model="item.current_password"
              type="password"
              :rules="pwdRules"
              :type="current_password ? 'text' : 'password'"
              :append-icon="current_password ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="current_password = !current_password"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <v-text-field
              label="New Password"
              outlined
              v-model="item.new_password"
              type="password"
              :rules="pwdRules"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <v-text-field
              outlined
              label="Confirm Password"
              v-model="item.confirm_password"
              :rules="pwdConfirm"
              type="password"
              :type="showPassword1 ? 'text' : 'password'"
              :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword1 = !showPassword1"
          >
          </v-text-field>
        </v-col>
        <div class="button-confirm d-block">
          <div @click="saveChange" class="btn-confirm rounded-sm">OK</div>
        </div>
      </v-row>
      <div class="button-confirm" v-if="!checkStatusChangePassword">
        <div class="btn-confirm" @click="confirmChangePassword">Confirm</div>
        <div class="btn-cancels" @click="next">Next<span><i class="fas fa-long-arrow-alt-right"></i></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "changePassword",
  data() {
    return {
      showPassword: false,
      current_password: false,
      showPassword1: false,
      checkStatusChangePassword: false,
      item: {
        new_password: "",
        confirm_password: "",
        current_password: "",
      },

      pwdRules: [v => !!v || "Password required"],
      pwdConfirm: [
        v => !!v || "Confirm password",
        v => v === this.item.new_password || "Passwords do not match"
      ]
    }
  },
  methods: {
    confirmChangePassword() {
      this.checkStatusChangePassword = true;
    },
    saveChange() {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {Authorization: `Bearer ${token}`}
      };
      this.$axios.post(`user/change/password`, this.item, config).then((res) => {
        if (res.status === 200) {
          //remove token in Localstorage,
          //remove role in Localstorage
          localStorage.removeItem("access_token");
          localStorage.removeItem("user_role");
          localStorage.removeItem("statusChangePassword")
          this.$router.push({
            name: "Dashboard"
          })
        }
      })
    },
    next() {
      this.$router.push({
        name: "Dashboard"
      })
      window.location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
.change-password {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(218, 222, 225);

  .card-change-password {
    width: 500px;
    height: auto;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 40px;

    .icon {
      width: 120px;
      height: 120px;
      background-color: #719AFF;
      border-radius: 30px;

      i {
        font-size: 4rem;
        color: #FFFFFF;
        font-weight: 900;
      }
    }

    h1 {
      font-size: 1.8rem;
      font-weight: 800;
      font-family: $font-family;
      margin-top: 8px;
      color: #313131;
    }

    .button-confirm {
      width: 100%;
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-confirm, .btn-cancels {
        width: 200px;
        height: 55px;
        background-color: #719AFF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        font-family: $font-family;
        text-transform: capitalize;
        color: #FFFFFF;
        font-size: 18px;
        cursor: pointer;
        border: 1px solid #eeeeee;

        &:hover {
          border: none;
          color: #FFFFFF;
        }
      }

      .btn-cancels {
        margin-left: 8px;
        background-color: rgba(46, 204, 113, 1.0);
        border: 1px solid #eeeeee;
        color: #FFFFFF;
        position: relative;

        span {
          font-size: 2rem;
          position: absolute;
          right: 20px;
        }
      }
    }
  }
}
</style>
