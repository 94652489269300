import axios from 'axios'
import store from './index'

const $axios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL,
    }
);


const state = {
    company: {},
};
const getters = {
    getCompany(state) {
        return state.company;
    },

};
const mutations = {
    setEditCompany(state, payload) {
        state.company = payload;
    }
};
const actions = {
    EditCompany(context, payload) {
        context.commit('setEditCompany', payload)
    },

    UpdateCompany(context, company) {
        $axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
        $axios.put(`admin/company/${company.id}`, company).then(() => {
        }).catch(() => {
        })
    }


};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
