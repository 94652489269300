import Vue from "vue";
import Vuex from "vuex";
import User from "@/store/User";
import Currency from "@/store/Currency";
import BusinessType from "@/store/BusinessType";
import Company from "@/store/Company";
import Employee from "@/store/Employee";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modalNotificationState: false,
    modalEditState: false,
    modalDeleteState: false,
    modalConfirmState: false,
    modalAddState: false,
    //format input salary
    formatSalaryNumber: {
      length: 11,
      precision: 0
    },
    //menu navbar
    menus: {
      users: {
        roles: ["superadmin", "synergyadmin", "companyadmin", "user"]
      }
    }
  },
  mutations: {
    modalNotification_State(state, value) {
      state.modalNotificationState = value;
    },

    modalEdit_State(state, value) {
      state.modalEditState = value;
    },

    modalDelete_State(state, value) {
      state.modalDeleteState = value;
    },

    modalConfirm_State(state, value) {
      state.modalConfirmState = value;
    },

    modalAdd_State(state, value) {
      state.modalAddState = value;
    }
  },
  actions: {},
  modules: {
    User,
    Currency,
    BusinessType,
    Company,
    Employee
  }
});
