import axios from "axios";
import store from './index';

const $axios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL,
    }
);

const state = {
    businessType: [],
    businessTypeUpdate: {},
};
const getters = {
    getBusinessType(state) {
        return state.businessType
    },
    getBusinessTypeUpdate(state){
        return state.businessTypeUpdate
    }
};
const mutations = {
    setBusinessType(state, payload) {
        state.businessType = payload;
    },

    setBusinessTypeUpdate(state, payload) {
        state.businessTypeUpdate = payload;
    }

};
const actions = {
    fetchBusinessType(context) {
        $axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
        $axios.get(`admin/type-business`).then((res) => {
            console.log(res)
            context.commit('setBusinessType', res.data.data)
        })
    },

    filterBusinessType(context, payload) {
        context.commit('setBusinessTypeUpdate', payload)
    },

    UpdateBusinessType(context,businessType) {
        $axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
        $axios.put(`admin/type-business/${businessType.id}`, businessType).then(() => {
        }).catch(() => {
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
