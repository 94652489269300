<template>
  <ValidationProvider
      class="relative appearance-none w-full TextInput"
      :vid="vid"
      :rules="rules"
      :name="name || label"
      v-slot="{ errors, required, ariaInput }"
  >
    <div class="con-form">
      <label :class="symbolstar ? 'required':''">{{ label }}</label>
      <vs-input
          :id="name"
          :type="type"
          :placeholder="placeholder"
          ref="input"
          v-model="innerValue"
          :state="errors[0]?'danger':''"
          v-bind="ariaInput"
          @input="OnInput"
          @change="OnChage"
          :disabled="disabled"
          :shadow="shadow"
          :border="border"
      >
        <template #icon v-if="icon">
          <i :class="icon"></i>
        </template>
        <template #message-danger>
          <p style=" font-weight: 900;letter-spacing: -1px">
            {{ errors[0] ? `${name} field is required!! ` : '' }}
          </p>
        </template>
      </vs-input>
    </div>


  </ValidationProvider>
</template>

<script>


export default {
  name: "TextInput",
  props: {
    vid: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    icon: {
      default: false
    },
    disabled: {
      default: false
    },
    shadow: {
      default: false
    },
    border: {
      default: false
    },
    type: {
      type: String,
      default: "text",
      validator(value) {
        return [
          "url",
          "text",
          "password",
          "tel",
          "search",
          "number",
          "email",
          'date',
          'time'
        ].includes(value);
      }
    },
    value: {
      type: null,
      default: ""
    },
    symbolstar: {
      default: true
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    hasValue() {
      return !!this.innerValue;
    }
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    }
  },
  methods: {
    OnInput(e) {
      this.$emit("InputChange", e);
    },
    OnChage(e) {
      this.$emit("OnChangeEmit", e.target.value);
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
