const Helpers = {};
Helpers.install = (Vue) => {
    const helpers = {
        numberFormatter(x) {
            return x.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        numberFormatterDecimal(x) {
            return x.toFixed(2);
        },

        getDateTime(time) {
            const mDate = !!time ? new Date(time) : new Date();
            let dd = mDate.getDate();
            const mm = mDate.getMonth();
            let hrs = mDate.getHours();
            let mns = mDate.getMinutes();
            let secs = mDate.getSeconds();
            if (hrs < 10) {
                hrs = `0${hrs}`;
            }
            if (mns < 10) {
                mns = `0${mns}`;
            }
            if (secs < 10) {
                secs = `0${secs}`;
            }
            const fullYear = mDate.getFullYear();
            const dayOfWeek = mDate.getDay();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            // +1 January is 0!
            let mmNumber = mm + 1;
            if (mmNumber < 10) {
                mmNumber = `0${mmNumber}`;
            }

            const monthNames = [
                'January', 'February', 'March',
                'April', 'May', 'June', 'July',
                'August', 'September', 'October',
                'November', 'December',
            ];
            const dayNames = [
                'Sunday', 'Monday', 'Tuesday',
                'Wednesday', 'Thursday', 'Friday', 'Saturday',
            ];
            return {
                hours: hrs,
                minutes: mns,
                seconds: secs,
                days: dd,
                days_name: dayNames[dayOfWeek],
                months: monthNames[mm],
                months_number: mmNumber,
                years: fullYear,
            };
        },
    };



    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$helpers = {...helpers };
};

export default Helpers;