import axios from 'axios'
import store from './index'

const $axios = axios.create(
    {
        baseURL: process.env.VUE_APP_BASE_API_URL,
    }
);

const state = {
    currencies: [],
    currency: {},
};

const getters = {
    //for fetchData
    getCurrencies(state) {
        return state.currencies;
    },
    //for edit
    getCurrency(state) {
        return state.currency
    }
};

const mutations = {
    setCurrencies(state, payload) {
        state.currencies = payload;
    },
    setCurrency(state, payload) {
        state.currency = payload;
    },
    setFilterCurrency(state, payload) {
        state.currency = payload
    }
};

const actions = {
    fetchCurrency(context) {
        $axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
        $axios.get(`admin/currency`).then((res) => {
            context.commit('setCurrencies', res.data.data)
        })
    },
    //for edit
    filterCurrency(context, payload) {
        context.commit('setFilterCurrency', payload)
    },
    //for update
    UpdateCurrency(context, currency) {
        $axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['User/getToken'];
        $axios.put(`admin/currency/${currency.id}`, currency).then(() => {
        }).catch(() => {
        })
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
