import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "../src/plugins/Axios";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "../src/assets/scss/main.scss";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import "bulma/css/bulma.css";
import Can from "@/Helpers/Can";
import Helpers from "@/Helpers";
import * as Notification from "./plugins/Notification";
import ModalNotification from "./components/Notification";
import ModalEdit from "./components/Modal/modalEdit";
import ModalDelete from "./components/Modal/modalDelete";
import ModalConfirm from "./components/Modal/modalConfirm";
import ModalAdd from "./components/Modal/modalAdd";
import DatePicker from "vue2-datepicker";
import { Chart } from "chart.js";
import Chartkick from "vue-chartkick";
import VuetifyMoney from "vuetify-money";
Vue.use(VuetifyMoney);
Vue.use(Chartkick.use(Chart));
import XLSX from "xlsx";

import * as VueGoogleMaps from "vue2-google-maps";

import * as moment from "moment";
import i18n from "./i18n";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormInput from "@/components/FormComponent/FormInput";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

Vue.component("FormInput", FormInput), (Vue.prototype.moment = moment);
Vue.component("ModalNotification", ModalNotification);
Vue.component("ModalEdit", ModalEdit);
Vue.component("ModalDelete", ModalDelete);
Vue.component("ModalAdd", ModalAdd);
Vue.component("ModalConfirm", ModalConfirm);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.prototype.$notification = Notification;
Vue.use(Helpers);
Vue.prototype.$can = Can;
Vue.use(Vuesax, {});
Vue.use(DatePicker);
Vue.use(XLSX);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCgUsajH7cANXTJDpXJCT9x2n2Hj9kN0OE",
    libraries: "places"
  }
});
// import io from "socket.io-client";

Vue.config.productionTip = false;

// Set up the Socket.IO connection
// const socket = io("https://socket.synergylao.com");
// Vue.prototype.$socket = socket; // Add socket to the Vue prototype to make it accessible in components

// import Echo from "laravel-echo";

// window.Pusher = require("pusher-js");

// window.Echo = new Echo({
//   api_key: process.env.VUE_APP_PUSHER_APP_KEY,
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//   cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
//   wssPort: 80,
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true,
//   enabledTransports: ["wss", "ws"]
// });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
