<template>
    <div>
        <v-list>

            <v-list-item-group>
                <!-- <v-list-item class="v-list-item-main">
                <v-list-item-icon class="icon">
                  <v-icon>mdi-file-sign</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-danger list-header">The contract is about to
                    exprice</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

                <v-list-item class="v-list-item-sub" v-for="(item, index) in employeeData"
                    @click="nextToEmployeeEndContract">
                    <v-list-item-icon>
                        <div v-if="item.profile == null" class="demo-profile">
                            <i class="fal fa-user-alt"></i>
                        </div>
                        <div v-else>
                            <!-- <img src="http://api.synergylao.com/storage/images/Employee/Profiles/emp_profile_1666768310.jpeg"
                                alt="" width="40" style="border-radius: 50%;"> -->
                            <img :src="item.profile" alt="" width="40" style="border-radius: 50%;">
                        </div>

                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="list-item">
                            <p :class="setMainColor(item.contract_expired)">{{ item.name }} {{ item.surname }}</p>
                            <span :class="setSubColor(item.contract_expired)" style="">the contract is about to expired {{
                                item.end_date }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list-item-group>

        </v-list>
    </div>
</template>

<script>
export default {
    props: {
        employeeData: []
    },
    data() {
        return {

        }
    },
    methods: {

        setMainColor(status) {
            if (status == 'warning') return 'text-warning-main';
            if (status == 'expired') return 'text-danger-main';
        },
        setSubColor(status) {
            if (status == 'warning') return 'text-warning-sub';
            if (status == 'expired') return 'text-danger-sub';
        },
        nextToEmployeeEndContract() {
            this.$router
                .push({
                    name: "employee_contract.expire",
                })
                .catch(() => { });
        }
    },
}
</script>

<style lang="scss" scoped>
.text-danger-main {
    color: red;
    font-size: 18px;
}

.text-danger-sub {
    color: rgb(185, 87, 87);
}

.text-warning-main {
    color: rgb(255, 204, 0);
    font-size: 18px;
}

.text-warning-sub {
    color: rgb(212, 176, 32);
}

.v-list-item-sub {
    min-height: fit-content;
}

.v-list-item-main {
    min-height: fit-content;

    .icon {
        margin: 10px 30px 10px 0px;
    }
}

.list-header {
    font-size: 18px;
}

.list-item {
    font-size: 14px;
}
</style>