<template>
  <div class="reset-password">
    <div class="form-reset-password">
      <div class="top-title">
        <img :src="require('@/assets/verified.png')" alt="" />
        <h1>ປ້ອນລະຫັດທີ່ໄດ້ຮັບທາງ Email</h1>
      </div>
      <div class="form">
        <v-row class="mt-5">
          <v-col cols="12" md="12" lg="12">
            <v-text-field
              outlined
              class="label-input"
              label="ປ້ອນລະຫັດຜ່ານໃໝ່"
              v-model="item.new_password"
              :hint="`${server_errors.new_password}`"
              persistent-hint
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="12">
            <v-text-field
              outlined
              class="label-input"
              label="ຍືນຍັນລະຫັດຜ່ານ"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              v-model="item.confirm_password"
              :hint="`${server_errors.confirm_password}`"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="12">
            <v-btn
              style="width: 50%; height: 45px"
              class="btn-save-change"
              @click="verifyCodeEmail"
              :loading="btnLoading"
            >
              ຍືນຍັນ
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      showPassword: false,
      btnLoading: false,
      item: {
        new_password: "",
        confirm_password: "",
      },
      server_errors: {
        new_password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    verifyCodeEmail() {
      const user = this.$route.query.user_id;
      const verify_code = this.$route.query.verify_code;
      const items = {
        user_id: user,
        verify_code: verify_code,
        new_password: this.item.new_password,
        confirm_password: this.item.confirm_password,
      };
      this.btnLoading = true;
      this.$axios
        .post(`reset-my-password`, items)
        .then((res) => {
          if (res.status === 200) {
            this.btnLoading = false;
            this.$router
              .push({
                name: "ForgotPasswordSuccess",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.reset-password {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;

  .form-reset-password {
    width: 700px;
    height: 650px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;

    .top-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
      }

      h1 {
        font-family: $font-family;
        font-size: 1.4rem;
        margin-top: 20px;
      }
    }

    .form {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
