import router from "@/router";
import { $axios } from "@/plugins/Axios";

const state = {
  token: localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : null,
  userProfile: {},
  msgErrors: ""
};

const getters = {
  LoggedIn(state) {
    return state.token !== null;
  },
  getUserType(state) {
    let profile = window.localStorage.getItem("user_profile");
    if (profile) {
      try {
        profile = JSON.parse(profile);
      } catch (err) {
        profile = {};
      }
    } else {
      profile = {};
    }

    if (Object.keys(state.userProfile).length) {
      return state.userProfile;
    }
    return profile.role_user;
  },
  getToken(state) {
    return state.token;
  },
  isAuth(state) {
    return state.token && state.token !== null;
  },
  ShowMsgErrors(state) {
    return state.msgErrors;
  }
};

const mutations = {
  AdminSigIn(state, token) {
    state.token = token;
  },
  destroyToken(state) {
    state.token = null;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  Commit_ErrorLogin(state, payload) {
    state.msgErrors = payload;
  },
  statusChangePassword() {}
};

const actions = {
  LoginUser(context, data) {
    return new Promise((resolve, reject) => {
      $axios
        .post("auth/login", {
          email: data.email,
          password: data.password
        })
        .then((response) => {
          if (response) {
            resolve(response);
            const token = response.data.access_token;
            const user = response.data.authUser.user;
            const userRole = response.data.authUser.roleUser[0];
            let userRoleLm = false;
            let userRoleHr = false;
            for (let i = 0; i < response.data.authUser.roleUser.length; i++) {
              let role = response.data.authUser.roleUser[i];
              if (role == "linemanager") {
                userRoleLm = true;
              }
              if (role == "hrmanager") {
                userRoleHr = true;
              }
            }
            const userPermission = response.data.authUser.permissionUsers;
            const statusChangePassword =
              response.data.authUser.user.status_change_password;
            localStorage.setItem("synergy_user", JSON.stringify(user));
            localStorage.setItem(
              "userPermission",
              JSON.stringify(userPermission)
            );
            localStorage.setItem("statusChangePassword", statusChangePassword);
            localStorage.setItem("access_token", token); // ເກັບ Token ໄວ້ໃນ Localstorage ເພື່ອຈະນຳໄປໃຊ້ຂໍຂໍ້ມູນ
            context.commit("AdminSigIn", token);
            context.commit("statusChangePassword", statusChangePassword);
            context.commit("setUserProfile", userRole);
            window.localStorage.setItem("user_role", JSON.stringify(userRole));
            window.localStorage.setItem("user_role_lm", userRoleLm);
            window.localStorage.setItem("user_role_hr", userRoleHr);
            $axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            switch (userRole) {
              case "superadmin":
                router.push({ name: "Dashboard" });
                break;
              case "synergyadmin":
                router.push({ name: "Dashboard" });
                break;
              case "synergyuser":
                router.push({ name: "Dashboard" });
                break;

              case "companyadmin":
                router.push({ name: "CompanyDashboard" });
                break;

              case "companyuser":
                router.push({ name: "CompanyDashboard" });
                break;
              case "employee":
                router.push({ name: "EmployeeDashboard" });
                break;
            }
          } else {
            context.commit("error_msg_login", response.data.msg);
            setTimeout(() => {
              context.commit("error_msg_login", "");
            }, 3000);
          }
        })
        .catch((error) => {
          reject(error);
          if (error.response.status === 401) {
            context.commit(
              "Commit_ErrorLogin",
              "ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ..."
            );
            setTimeout(() => {
              context.commit("Commit_ErrorLogin", "");
            }, 3000);
          } else if (error.response.status === 400) {
            context.commit(
              "Commit_ErrorLogin",
              "ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ..."
            );
            setTimeout(() => {
              context.commit("Commit_ErrorLogin", "");
            }, 3000);
          }
        });
    });
  },

  destroyToken(context) {
    if (context.getters.LoggedIn) {
      return new Promise((resolve, reject) => {
        $axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
        $axios
          .post("auth/logout")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally((response) => {
            resolve(response);
            localStorage.removeItem("access_token"); // Remove Item Of Localstorage...
            localStorage.removeItem("user_profile"); // Remove Item Of Localstorage...
            localStorage.removeItem("synergy_user"); // Remove Item Of Localstorage...
            context.commit("destroyToken");
            router
              .push({
                name: "Login"
              })
              .catch(() => {});
          });
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
