<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "AppLayout"
}
</script>

<style scoped>

</style>
